<script setup lang="ts">
const { t: $t } = useI18n()

import { useQRCode } from '@vueuse/integrations/useQRCode'
import { ref } from 'vue'

const props = defineProps<{ entity: { id: string, type: 'machinery' | 'machineryAccessory', defectId?: string } }>()

const { defect: queryDefect } = useQuery()
const { data: entityDefectData, isLoading, isError, error } = queryDefect.getServiceRepairJobInformationForEntity(computed(() => props.entity))

const qrcode = useQRCode(useFullCurrentUrl())

const pdfSection = ref<HTMLElement | null>(null)
const { generatePDF, isExportingToPDF } = useHTMLElementToPDFExporter()

const formattedTypeName = computed(() => entityDefectData.value?.typeName ?? $t('common.unknown'))

const router = useRouter()

const highPrioIconToUse = computed(() => isExportingToPDF.value ? 'U' : '🔴')
const lowPrioIconToUse = computed(() => isExportingToPDF.value ? 'A' : '🟡')
</script>

<template>
  <div>
    <div ref="pdfSection" class="mx-4 w-96">
      <img :src="qrcode" alt="QR Code" class="h-64 mb-4">
      <TheDataCard title="Service Auftrag" :is-loading="isLoading" :is-error="isError" :error="error">
        <template #summary>
          <h1 class="font-bold text-xl">
            Auftrag zu {{ entity.id }} (Typ: {{ formattedTypeName }})
          </h1>
        </template>
        <div v-if="!entityDefectData">
          Gerät existiert nicht.
        </div>

        <div v-if="entityDefectData && entityDefectData.defects.length > 0">
          <div v-for="(defect, index) in entityDefectData?.defects" :key="defect.id">
            <p class="text-lg mt-4">
              Defekt {{ index + 1 }} ({{ defect.priority === 0 ? highPrioIconToUse : lowPrioIconToUse }}): {{ defect.description }}
            </p>
          </div>
          <p v-if="!isExportingToPDF" class="mt-4">
            ({{ lowPrioIconToUse }} = Aufschiebbar, {{ highPrioIconToUse }} = Unaufschiebbar)
          </p>
          <p v-else>
            (A = Aufschiebbar, U = Unaufschiebbar)
          </p>
        </div>
      </TheDataCard>
    </div>
    <div class="mt-4 ml-4 flex flex-col space-y-2 max-w-lg">
      <n-button type="primary" :loading="isExportingToPDF" @click="() => generatePDF(`service-auftrag-${props.entity.id}.pdf`, pdfSection)">
        Als PDF laden
      </n-button>
      <n-button type="primary" @click="router.back()">
        Zurück zur vorigen Seite
      </n-button>
      <n-button v-if="entity.defectId" type="primary" @click="navigateTo('.')">
        Alle Service-Aufträge zu {{ entity.id }}
      </n-button>
    </div>
  </div>
</template>
