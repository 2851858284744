/**
 * Adapted from:
 * 1. https://github.com/sidebase/nuxt-pdf/blob/main/src/runtime/composables/exportToPDF.ts
 * 2. https://github.com/sidebase/nuxt-pdf/blob/main/src/runtime/composables/htmlToPDF.ts
 */
import jsPDF, { type HTMLOptions, type jsPDFOptions } from 'jspdf'

function htmlElementToPDFFile(pdfFilename: string, element: HTMLElement, documentOptions?: jsPDFOptions, htmlOptions?: HTMLOptions) {
  const orientation = (element.offsetWidth > element.offsetHeight) ? 'l' : 'p'

  // eslint-disable-next-line new-cap
  const pdf = new jsPDF({
    orientation: documentOptions?.orientation ?? orientation,
    unit: documentOptions?.unit ?? 'px',
    format: documentOptions?.format ?? 'A4',
    encryption: documentOptions?.encryption,
  })

  return pdf
    .html(element, htmlOptions)
    .save(pdfFilename)
}

/**
 * Provides a helper to export a passed `HTMLElement` to a PDF. Calling `generatePDF` will trigger the download / save action of said PDF right away.
 */
export default function () {
  const isExportingToPDF = ref(false)
  const notification = useNotification()

  async function generatePDF(
    fileName: string,
    pdfSection: HTMLElement | null,
    { orientation = 'p', format = 'a4', margin = [10, 0, 10, 0] }: { orientation?: 'p' | 'l', format?: string | number[], margin?: number[] } = {},
  ) {
    if (!pdfSection) {
      notification.warning({ description: 'PDF-Export lädt noch, bitte versuchen Sie es in wenigen Sekunden nochmal' })
      return
    }

    isExportingToPDF.value = true
    await sleep(500)
    await htmlElementToPDFFile(fileName, pdfSection, { orientation, format }, { margin, autoPaging: 'text' })
    isExportingToPDF.value = false
  }

  return {
    isExportingToPDF,
    generatePDF,
  }
}
