<script setup lang="ts">
import { z } from 'zod'
import { idSchema } from '~/server/schemas'

const { params } = useRoute()

const entity = z.object({
  id: idSchema,
  /**
   * For historic reasons we have to stay with `storage-tool` here: As it is part of the URL and we used to create QR Codes containing this URL we need to stay with this old naming to ensure that the old QR-codes still work.
   *
   * We re-map `storage-tool` to `machineryAccessory` so that we progessively fix this over time (while in theory always supporting it).
   *
   * Introduced in https://github.com/sidestream-tech/hanselmann-os/pull/4161
   */
  type: z.enum(['storage-tool', 'machinery']).transform(v => v === 'storage-tool' ? 'machineryAccessory' : v),
  /**
   * If a defectId is passed we only show the specific defect on the service job page. Otherwise we show all related defects
   */
  defectId: z.string().nullish().transform((v) => {
    if (!v) {
      return undefined
    }

    return v
  }),
})
  .parse(params)
</script>

<template>
  <ThePage title="Service" icon-name="material-symbols:service-toolbox-outline-rounded">
    <ServiceJobDetailsPage :entity />
  </ThePage>
</template>
